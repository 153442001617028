<template>
  <InternalLayout>
    <template slot="body">
      <div class="margin-container my-5">
        <Tabs
          name="test"
          class="mt-9"
          :tabs="['Tudo', 'Vender', 'Arrendar']"
        />
        <NumberInput class="mt-9" />
        <BlueCollapse
          class="mt-9"
          title="Teste"
          text="fwe fwef wefw efwqef wef wef"
        />
        <DocumentUploadCard class="mt-9" />
        <ListingOfferCard class="mt-9" />
        <TitleStep
          class="mt-9"
          number="1"
          text="Preencha o endereço do imóvel"
        />
        <Alert
          class="mt-9"
          text="Preencha o endereço do imóvel"
        />
        <div
          class="test mt-9"
        >
          <div class="item1">
            <span class="choice-card__text">
              item1
            </span>
          </div>
          <div class="item2">
            <span class="choice-card__text">
              item2
            </span>
          </div>
          <div class="item1">
            <span class="choice-card__text">
              item3
            </span>
          </div>
          <div class="item2">
            <span class="choice-card__text">
              item4
            </span>
          </div>
        </div>

        <CheckboxGroup
          class="mt-9"
          :characteristics="[
            'Lareira',
            'Varanda',
            'Piscina',
            'BBQ',
            'Terraço',
            'Lareira',
            'Varanda',
            'Piscina',
            'BBQ',
            'Terraço',
            'Lareira',
            'Varanda',
            'Piscina',
            'BBQ',
            'Terraço',
          ]"
          :breakpoint="
            this.getBreakpoints.mdAndUp
              ? 'md'
              : this.getBreakpoints.sm
                ? 'sm'
                : 'xs'
          "
        />

        <div
          id="kontomatik"
          class="mt-9"
        />
      </div>
    </template>
  </InternalLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  BlueCollapse,
  DocumentUploadCard,
  NumberInput,
  Tabs,
  TitleStep,
  Alert,
  CheckboxGroup,
} from 'ff-components-lib';

import InternalLayout from '@/components/layout/internal/InternalLayout';
import ListingOfferCard from '@/components/ui/ListingOfferCard';

export default {
  name: 'ListingsScreen',
  components: {
    InternalLayout,
    Tabs,
    NumberInput,
    BlueCollapse,
    DocumentUploadCard,
    ListingOfferCard,
    TitleStep,
    Alert,
    CheckboxGroup,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
  },
  mounted() {
    /* const widgetScript = document.createElement('script');
    widgetScript.type = 'application/javascript';
    widgetScript.src = 'https://signin.kontomatik.com/assets/signin-widget.js';
    document.head.appendChild(widgetScript);

    widgetScript.onload = () => {
      console.log('TEST');
      // eslint-disable-next-line no-undef
      embedKontomatik({
        client: 'firstfloor-test', // replace it with your assigned client id
        divId: 'kontomatik', // must match the div element id
        ownerExternalId: 'firstfloor',
        country: 'pt',
        locale: 'pt',
        onSuccess(target, sessionId, sessionIdSignature, options) {
        // End-user successfully signed in to the bank
        // Pass target, sessionId and sessionIdSignature to your backend
          console.log('It works!');
          console.log(target);
          console.log(sessionId);
          console.log(sessionIdSignature);
          console.log(options);
        },
      });
    }; */
  },
};
</script>
<style lang="scss" scoped>
.test {
  display: grid;
  grid-template-columns: auto auto;
}

.item1 {
  background-color: red;
  grid-column-start: 1;
  grid-column-end: 2;
}

.item2 {
  background-color: blue;
  grid-column-start: 2;
  grid-column-end: 3;
}
</style>
