<template>
  <div class="listing-offer-card">
    <div class="listing-offer-card__top">
      <div class="listing-offer-card__user">
        <img
          src="https://buefy.org/static/img/placeholder-1280x960.png"
          class="listing-offer-card__user--image"
        >
        <span class="listing-offer-card__user--name">
          Filipa Martins
        </span>
      </div>
      <Status text="Pendente" />
    </div>
    <div class="listing-offer-card__separation" />
    <div class="listing-offer-card__bottom" />
  </div>
</template>
<script>
import { Status } from 'ff-components-lib';

export default {
  name: 'ListingOfferCard',
  components: {
    Status,
  },
  data() {
    return {
      status: 'pending',
    };
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.listing-offer-card {
  display: flex;
  flex-direction: column;

  background: white;
  box-shadow: 0px 4px 10px rgba($primary, 0.1);
  border-radius: 8px;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 24px;
    margin-right: 0;
  }

  &__user {
    display: flex;
    align-items: center;
    gap: 8px;

    &--image {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }

    &--name {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  &__bottom {
    display: flex;
    margin: 16px 24px;
  }

  &__separation {
    width: calc(100% - 48px);
    height: 1px;
    background: rgba($primary, 0.1);
    align-self: center;
  }

  /*.mdi {
    font-size: 24px;
    height: 24px;
    line-height: 1;
  }*/
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  border-radius: 24px 0 0 24px;

  font-size: 12px;
  font-weight: 600;
  line-height: 15px;

  &.blue {
    background: rgba($primary, 0.2);
    color: $primary;
  }

  &.green {
    background: rgba($green, 0.2);
    color: $green;
  }

  &.red {
    background: rgba($red, 0.2);
    color: $red;
  }
}
</style>
