var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('InternalLayout',[_c('template',{slot:"body"},[_c('div',{staticClass:"margin-container my-5"},[_c('Tabs',{staticClass:"mt-9",attrs:{"name":"test","tabs":['Tudo', 'Vender', 'Arrendar']}}),_c('NumberInput',{staticClass:"mt-9"}),_c('BlueCollapse',{staticClass:"mt-9",attrs:{"title":"Teste","text":"fwe fwef wefw efwqef wef wef"}}),_c('DocumentUploadCard',{staticClass:"mt-9"}),_c('ListingOfferCard',{staticClass:"mt-9"}),_c('TitleStep',{staticClass:"mt-9",attrs:{"number":"1","text":"Preencha o endereço do imóvel"}}),_c('Alert',{staticClass:"mt-9",attrs:{"text":"Preencha o endereço do imóvel"}}),_c('div',{staticClass:"test mt-9"},[_c('div',{staticClass:"item1"},[_c('span',{staticClass:"choice-card__text"},[_vm._v(" item1 ")])]),_c('div',{staticClass:"item2"},[_c('span',{staticClass:"choice-card__text"},[_vm._v(" item2 ")])]),_c('div',{staticClass:"item1"},[_c('span',{staticClass:"choice-card__text"},[_vm._v(" item3 ")])]),_c('div',{staticClass:"item2"},[_c('span',{staticClass:"choice-card__text"},[_vm._v(" item4 ")])])]),_c('CheckboxGroup',{staticClass:"mt-9",attrs:{"characteristics":[
          'Lareira',
          'Varanda',
          'Piscina',
          'BBQ',
          'Terraço',
          'Lareira',
          'Varanda',
          'Piscina',
          'BBQ',
          'Terraço',
          'Lareira',
          'Varanda',
          'Piscina',
          'BBQ',
          'Terraço' ],"breakpoint":this.getBreakpoints.mdAndUp
            ? 'md'
            : this.getBreakpoints.sm
              ? 'sm'
              : 'xs'}}),_c('div',{staticClass:"mt-9",attrs:{"id":"kontomatik"}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }